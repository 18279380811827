import React from 'react'
import QuickNav from './includes/QuickNav'
import Navigation from './includes/Navigation'

const Dashoard = () => {
  return (
    <>
        <div className="container-fluid mt-xl-50 mt-sm-30 mt-15 pt-4">
        <div className="hk-pg-header pt-4 pb-4 mt-2 mb-3 d-flex justify-content-between">
            <div>
                <h2 className="hk-pg-title font-weight-600">Super Admin Dashboard</h2>
                <p>Welcome to Customer Relationship Management for SellMore..</p>
            </div>

            <QuickNav />

        </div>

        <Navigation/>

        <div class="row">
<div class="col-xl-12">
<div class="hk-row">
<div class="col-lg-7">
<div class="row">
<div class="col-lg-4 col-sm-6">
<div class="bg-primary card card-sm">
<div class="card-body"> <span class="d-block font-11 font-weight-500 mb-10 text-uppercase text-white">Total Import Leads</span>
<div class="d-flex align-items-center justify-content-between position-relative">
   <div> <span class="d-block display-5 font-weight-400 text-white">23,546</span> </div>
</div>
</div>
</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="bg-dark card card-sm">
<div class="card-body"> <span class="d-block font-11 font-weight-500 mb-10 text-uppercase text-white">Total Assign Leads</span>
<div class="d-flex align-items-center justify-content-between position-relative">
   <div> <span class="d-block display-5 font-weight-400 text-white">12,484</span> </div>
</div>
</div>
</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="bg-yellow card card-sm">
<div class="card-body"> <span class="d-block font-11 font-weight-500 mb-10 text-uppercase text-white">Total My Leads</span>
<div class="d-flex align-items-center justify-content-between position-relative">
   <div> <span class="d-block display-5 font-weight-400 text-white">612</span> </div>
</div>
</div>
</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="bg-danger card card-sm">
<div class="card-body"> <span class="d-block font-11 font-weight-500 mb-10 text-uppercase text-white">Total Hot Leads</span>
<div class="d-flex align-items-center justify-content-between position-relative">
   <div> <span class="d-block display-5 font-weight-400 text-white">179</span> </div>
</div>
</div>
</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="bg-pink card card-sm">
<div class="card-body"> <span class="d-block font-11 font-weight-500 mb-10 text-uppercase text-white">total Dormant Leads</span>
<div class="d-flex align-items-center justify-content-between position-relative">
   <div> <span class="d-block display-5 font-weight-400 text-white">234</span> </div>
</div>
</div>
</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="bg-brown card card-sm">
<div class="card-body"> <span class="d-block font-11 font-weight-500 mb-10 text-uppercase text-white">Total Non Interested Leads</span>
<div class="d-flex align-items-center justify-content-between position-relative">
   <div> <span class="d-block display-5 font-weight-400 text-white">987</span> </div>
</div>
</div>
</div>
</div>
<div class="col-lg-12 col-sm-6">
<div class="card card-sm">
<div class="card-body"> <span class="d-block font-11 font-weight-500 text-dark text-uppercase mb-10">Total Virgin Leads</span>
<div class="d-flex align-items-center justify-content-between position-relative">
   <div> <span class="d-block display-5 font-weight-400 text-dark">6,536</span> </div>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-5">
<div class="card">
<div class="card-header card-header-action">
<h6>Sales Agent</h6>
<div class="d-flex align-items-center card-action-wrap"> </div>
</div>
<div class="card-body h-270p pa-0" style={{overflowY:'scroll'}}>
<div class="table-wrap">
<div class="table-responsive">
<table class="table table-sm table-hover mb-0">
   <thead>
      <tr>
         <th>Face</th>
         <th>Name</th>
         <th>Today</th>
         <th>Assign</th>
      </tr>
   </thead>
   <tbody>
      <tr>
         <td><img class="img-fluid rounded" src="FrontAsset/dist/img/logo1.jpg" alt="icon" /></td>
         <td>Rafay Siddqui</td>
         <td>65</td>
         <td>2,38</td>
      </tr>
      <tr>
         <td><img class="img-fluid rounded" src="FrontAsset/dist/img/logo1.jpg" alt="icon" /></td>
         <td>Rafay Siddqui</td>
         <td>65</td>
         <td>2,38</td>
      </tr>
      <tr>
         <td><img class="img-fluid rounded" src="FrontAsset/dist/img/logo1.jpg" alt="icon" /></td>
         <td>Rafay Siddqui</td>
         <td>65</td>
         <td>2,38</td>
      </tr>
      <tr>
         <td><img class="img-fluid rounded" src="FrontAsset/dist/img/logo1.jpg" alt="icon" /></td>
         <td>Rafay Siddqui</td>
         <td>65</td>
         <td>2,38</td>
      </tr>
      <tr>
         <td><img class="img-fluid rounded" src="FrontAsset/dist/img/logo1.jpg" alt="icon" /></td>
         <td>Rafay Siddqui</td>
         <td>65</td>
         <td>2,38</td>
      </tr>
      <tr>
         <td><img class="img-fluid rounded" src="FrontAsset/dist/img/logo1.jpg" alt="icon" /></td>
         <td>Rafay Siddqui</td>
         <td>65</td>
         <td>2,38</td>
      </tr>
   </tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="hk-row">
<div class="col-lg-6">
<div class="card">
<div class="card-header card-header-action">
<h6>calling history</h6>
<div class="d-flex align-items-center card-action-wrap">
<div class="d-flex align-items-center card-action-wrap">
<a href="#" class="inline-block refresh mr-15"> <i class="ion ion-md-arrow-down"></i> </a>
<a class="inline-block card-close" href="#" data-effect="fadeOut"> <i class="ion ion-md-close"></i> </a>
</div>
</div>
</div>
<div class="card-body">
<div class="row">
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-phone.png" alt="icon" /><br />
<h4>08</h4>
<small>Phone</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-whatsapp.png" alt="icon" /><br />
<h4>12</h4>
<small>Whatsapp</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-sms.png" alt="icon" /><br />
<h4>45</h4>
<small>SMS</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-envelope.png" alt="icon" /><br />
<h4>22</h4>
<small>Email</small>
</center>
</div>
</div>

</div>
<div class="card-header card-header-action">
<h6>DATA ANALYTICS</h6>
</div>
<div class="card-body">
<div class="row">
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-phone.png" alt="icon" /><br />
<h4>08</h4>
<small>Male</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-whatsapp.png" alt="icon" /><br/>
<h4>12</h4>
<small>Female</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-sms.png" alt="icon"/><br />
<h4>45</h4>
<small>Hot Leads</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-envelope.png" alt="icon" /><br />
<h4>22</h4>
<small>Email</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-envelope.png" alt="icon" /><br />
<h4>22</h4>
<small>International</small>
</center>
</div>
<div class="col">
<center>
<img class="img-fluid rounded w-60" src="FrontAsset/dist/img/icons/icn-envelope.png" alt="icon" /><br />
<h4>22</h4>
<small>Deal Won</small>
</center>
</div>
</div>

</div>
</div>
</div>
<div class="col-lg-6">
<div class="card">
<div class="card-header card-header-action">
<h6>Agent wise log</h6>
<div class="d-flex align-items-center card-action-wrap">
<div class="inline-block dropdown"> <a class="dropdown-toggle no-caret" data-toggle="dropdown" href="#" aria-expanded="false" role="button"><i class="ion ion-ios-more"></i></a>
<div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item" href="#">Action</a> <a class="dropdown-item" href="#">Another action</a> <a class="dropdown-item" href="#">Something else here</a>
   <div class="dropdown-divider"></div> <a class="dropdown-item" href="#">Separated link</a> </div>
</div>
</div>
</div>
<div class="card-body h-370p" style={{overflowY:'scroll'}}>
<div class="user-activity user-activity-sm">
<div class="media">
<div class="media-img-wrap">
<div class="avatar avatar-xs">
<img src="FrontAsset/dist/img/avatar2.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
</div>
<div class="media-body">
<div>
<span class="d-block mb-5"><span class="font-weight-500 text-dark text-capitalize">Laura Thompson</span><span class="pl-5">joined josh groben team.</span></span>
<span class="d-block font-13">3 hours ago</span>
</div>
</div>
</div>
<div class="media">
<div class="media-img-wrap">
<div class="avatar avatar-xs">
<img src="FrontAsset/dist/img/avatar3.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
</div>
<div class="media-body">
<div>
<span class="d-block mb-5"><span class="font-weight-500 text-dark text-capitalize">Meayme Fletcher</span><span class="pl-5">liked photos</span></span>
<span class="d-block font-13 mb-10">6 hours ago</span>
</div>

</div>
</div>
<div class="media">
<div class="media-img-wrap">
<div class="avatar avatar-xs">
<span class="avatar-text avatar-text-info rounded-circle">
      <span class="initial-wrap"><span>B</span></span>
</span>
</div>
</div>
<div class="media-body">
<div>
<span class="d-block mb-5"><span class="font-weight-500 text-dark text-capitalize">Billy Flowers</span><span class="pl-5">Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text.</span></span>
<span class="d-block font-13">3 days ago</span>
</div>
</div>
</div>
<div class="media">
<div class="media-img-wrap">
<div class="avatar avatar-xs">
<img src="FrontAsset/dist/img/avatar4.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
</div>
<div class="media-body">
<div>
<span class="d-block mb-5"><span class="font-weight-500 text-dark text-capitalize">Morgan Freeman</span><span class="pl-5">joined josh groben team.</span></span>
<span class="d-block font-13">3 hours ago</span>
</div>
</div>
</div>
<div class="media">
<div class="media-img-wrap">
<div class="avatar avatar-xs">
<span class="avatar-text avatar-text-success rounded-circle">
      <span class="initial-wrap"><span>M</span></span>
</span>
</div>
</div>
<div class="media-body">
<div>
<span class="d-block mb-5"><span class="font-weight-500 text-dark text-capitalize">Meayme Fletcher</span><span class="pl-5">liked photos</span></span>
<span class="d-block font-13 mb-10">6 hours ago</span>
</div>

</div>
</div>
<div class="media">
<div class="media-img-wrap">
<div class="avatar avatar-xs">
<img src="FrontAsset/dist/img/avatar6.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
</div>
<div class="media-body">
<div>
<span class="d-block mb-5"><span class="font-weight-500 text-dark text-capitalize">Evie Ono</span><span class="pl-5">joined josh groben team.</span></span>
<span class="d-block font-13">3 hours ago</span>
</div>
</div>
</div>
<div class="media">
<div class="media-img-wrap">
<div class="avatar avatar-xs">
<span class="avatar-text avatar-text-primary rounded-circle">
      <span class="initial-wrap"><span>J</span></span>
</span>
</div>
</div>
<div class="media-body">
<div>
<span class="d-block mb-5"><span class="font-weight-500 text-dark text-capitalize">Jonnie Metoyer</span><span class="pl-5">started following you</span></span>
<span class="d-block font-13 mb-10">2 days ago</span>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="d-flex align-items-center justify-content-between mt-40 mb-20">
<h4>Deal Done</h4>
<button class="btn btn-sm btn-link">view all</button>
</div>
<div class="card">
<div class="card-body pa-0">
<div class="table-wrap">
<div class="table-responsive">
<table class="table table-sm table-hover mb-0">
<thead>
<tr>
    <th>Client Name</th>
              <th>Mobile Number</th>
              <th class="text-center">Project</th>
              <th>Total Amount</th>
              <th>Recieved Amount</th>
              <th>Remaining Amount</th>
              <th>Date</th>
              <th>Action</th>
</tr>
</thead>
<tbody>
          <tr>
              <td>Nauman Bajwa</td>
              <td>03002896042</td>
              <td class="text-center"><img class="" width="50%" src="FrontAsset/dist/img/logos/asf-logo.png" alt="icon" /></td>
              <td>1,600,000</td>
              <td>1,300,000</td>
              <td>300,000</td>
              <td>2021-09-18</td>
              <td>
                  <button class="btn btn-icon btn-icon-circle btn-dark mr-10"><span class="btn-icon-wrap"><i class="icon-eye"></i></span></button>
              </td>
          </tr>
           <tr>
              <td>Nauman Bajwa</td>
              <td>03002896042</td>
              <td class="text-center"><img class="" width="50%" src="FrontAsset/dist/img/logos/bin-ahsan-logo.png" alt="icon" /></td>
              <td>1,600,000</td>
              <td>1,300,000</td>
              <td>300,000</td>
              <td>2021-09-18</td>
              <td>
                  <button class="btn btn-icon btn-icon-circle btn-dark mr-10"><span class="btn-icon-wrap"><i class="icon-eye"></i></span></button>
              </td>
          </tr>
           <tr>
              <td>Nauman Bajwa</td>
              <td>03002896042</td>
              <td class="text-center"><img class="" width="50%" src="FrontAsset/dist/img/logos/narkinslogo.png" alt="icon" /></td>
              <td>1,600,000</td>
              <td>1,300,000</td>
              <td>300,000</td>
              <td>2021-09-18</td>
              <td>
                  <button class="btn btn-icon btn-icon-circle btn-dark mr-10"><span class="btn-icon-wrap"><i class="icon-eye"></i></span></button>
              </td>
          </tr>
           <tr>
              <td>Nauman Bajwa</td>
              <td>03002896042</td>
              <td class="text-center"><img class="" width="50%" src="FrontAsset/dist/img/logos/indus-resort-logo.png" alt="icon" /></td>
              <td>1,600,000</td>
              <td>1,300,000</td>
              <td>300,000</td>
              <td>2021-09-18</td>
              <td>
                  <button class="btn btn-icon btn-icon-circle btn-dark mr-10"><span class="btn-icon-wrap"><i class="icon-eye"></i></span></button>
              </td>
          </tr>
      </tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>

    </div>
        

    </>
  )
}

export default Dashoard