import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useDispatch , useSelector } from "react-redux"
import { useNavigate , NavLink, useParams } from 'react-router-dom';
import OtherNavigation from '../../Includes/OtherNavigation'
import QuickNav from '../../Includes/QuickNav';
import { SecurityFilesAction } from '../../redux/action/SecurityFilesAction';
import { actionConfig } from '../../configuration';
import { getDashCountData } from '../../redux/action/DashboardCountAction';
import Moment from 'react-moment';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import ReactPaginate from 'react-paginate';
import { AllPaymentRecieptAction } from '../../redux/action/AllPaymentRecieptAction';
import { extendMoment } from 'moment-range';





const PaymentPlanGenerate = () => {

    const result = useSelector(state => state.dashCountReducer.dashboardcount);
    const resultSecurity = useSelector(state => state.SecurityFilesReducers.securityfiledata);
    const resultRolePermssion = useSelector(state => state.Permissiondatareducers.singledataredu);
    
    const allpaymentreciept = useSelector(state => state.AllPaymentRecieptreducers.allpaymentreciept);
    const dispatch = useDispatch();

    const FiltersSecurity = resultRolePermssion === '' ? '' : resultRolePermssion.filter(edx => edx.feature_id === 150 && edx.sub_features === 'Report');

   
    let { id } = useParams();

    const [DocumentType,setDocumentType]=useState('');
    const [search,setSearch]= useState('');
    const [PropertyType,setPropertyType]=useState('');
    const [DocumentReference,setDocumentReference]=useState('');
    const [DocumentStatus,setDocumentStatus]=useState('');
    const [Title,setTitle]=useState('');
    const [Description,setDescription]=useState('');
    const [PaymentPlanStatus,setPaymentPlanStatus]=useState('');
    const [PaymentPlanType,setPaymentPlanType]=useState('');
    const [PaymentPlanTypeButton,setPaymentPlanTypeButton]=useState(false);
    const [PaymentPlanShow,setPaymentPlanShow]=useState(false);
    const [SingleInveResult,setSingleInveResult]=useState([]);


    const [BookingAmountValue,setBookingAmountValue]=useState('');
    const [AllocationAmountValue,setAllocationAmountValue]=useState('');
    const [ConfirmationAmountValue,setConfirmationAmountValue]=useState('');
    const [PossessionAmountValue,setPossessionAmountValue]=useState('');
    const [DemarcationAmountValue,setDemarcationAmountValue]=useState('');


    const [PaymentHalfYearlyAmt,setPaymentHalfYearlyAmt]=useState('');
    const [PaymentQuarterlyAmt,setPaymentQuarterlyAmt]=useState('');
    const [PaymentMontlyAmt,setPaymentMontlyAmt]=useState('');
    
    

    useEffect(() => {
      dispatch(getDashCountData('all',''));
   },[]);

    useEffect(() => {
      dispatch(SecurityFilesAction(FiltersSecurity.length == 0 ? '':'all',1,12,DocumentType,PropertyType,DocumentReference,DocumentStatus,Title,Description));
  },[]);

    const handleSearch = async (e) => {
        e.preventDefault();
        dispatch(SecurityFilesAction(FiltersSecurity.length == 0 ? '':'all',1,12,DocumentType,PropertyType,DocumentReference,DocumentStatus,Title,Description));
    }

    
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content:() => componentRef.current,
      documentTitle:'testprint',
      onAfterPrint:()=>alert("Print Success")
    })

    console.log("result",result);

     const handlePageClick = (data) => {
    let currentPage = data.selected + 1
    dispatch(SecurityFilesAction(FiltersSecurity.length == 0 ? '':'all',currentPage,12,DocumentType,PropertyType,DocumentReference,DocumentStatus,Title,Description));
    
    }

    useEffect(() => {
      dispatch(AllPaymentRecieptAction(1,12));
      SingleRecieptInventory();
    },[]);

    useEffect(() => {
      if(PaymentPlanType == 'autogenerated'){
        setPaymentPlanShow(false);
        setPaymentPlanTypeButton(true);
      }else if(PaymentPlanType == 'customized'){
        setPaymentPlanShow(true);
        setPaymentPlanTypeButton(true);
      }else{
        setPaymentPlanShow(false);
        setPaymentPlanTypeButton(false);
      }
    },[PaymentPlanType == '' || PaymentPlanType == undefined || PaymentPlanType == null ? '' : PaymentPlanType]);

    const SingleRecieptInventory = async () => {
      const response = await fetch(`${actionConfig.REACT_APP_URL}paymentreciept/${id}`);
      const dataxs = await response.json();
      const GetArray = dataxs.data[0];
      setSingleInveResult(await GetArray);

      setBookingAmountValue(GetArray.ProjectFilter.booking.replace(/,/g, ''));
      setAllocationAmountValue(GetArray.ProjectFilter.allocation.replace(/,/g, ''));
      setConfirmationAmountValue(GetArray.ProjectFilter.confirmation.replace(/,/g, ''));
      setPossessionAmountValue(GetArray.ProjectFilter.possession.replace(/,/g, ''));
      setDemarcationAmountValue(GetArray.ProjectFilter.demarcation.replace(/,/g, ''));

      }

    console.log("PaymentPlanStatus",PaymentPlanStatus);
    
      let PlanYear = parseInt(PaymentPlanStatus * 12);
      let PlanHalfYear = parseInt(PaymentPlanStatus * 2);
      let PlanQuarterYear = parseInt(PaymentPlanStatus * 4);
    //   const HalfYear  = parseInt(PlanYear) / parseInt(PaymentPlanStatus);

    //   const QuartluxYear  = parseInt(HalfYear) / parseInt(PaymentPlanStatus);
    // console.log("HalfYear",HalfYear);

    const monthlyinstallmentAmt = parseFloat(SingleInveResult == '' ? '' : SingleInveResult.ProjectFilter.monthlyinstallment.replace(/,/g, ''));
    const HalfYearlyinstallmentAmt = parseFloat(SingleInveResult == '' ? '' : SingleInveResult.ProjectFilter.halfyearinstallment.replace(/,/g, ''));

    if(SingleInveResult == '' || SingleInveResult == null){
        var RecievedAmount = 0;
    }else{
      if(SingleInveResult.ModeOfPayment == 'cash'){
        var RecievedAmount = SingleInveResult.cashAmount;
      }else if(SingleInveResult.ModeOfPayment == 'Bank (Online)'){
        var RecievedAmount = SingleInveResult.bankonlineAmount;
      }else if(SingleInveResult.ModeOfPayment == 'Cheque'){
        var RecievedAmount = SingleInveResult.chequeAmount;
      }else if(SingleInveResult.ModeOfPayment == 'Pay Order | DD'){
        var RecievedAmount = SingleInveResult.payorderAmount;
      }else if(SingleInveResult.ModeOfPayment == 'File (Merging)'){
        var RecievedAmount = SingleInveResult.fileAmount;
      }else{
        var RecievedAmount = 0;
      }
    }

    if(SingleInveResult == '' || SingleInveResult == null){
      var TOtalAmc =  0;
    }else{
      var TOtalAmc =  SingleInveResult.TotalAmount - RecievedAmount;
    }
    

    const start = moment().add(+30, 'm');


    let QuarterlyAmountData = PaymentQuarterlyAmt == '' ? '0' : parseInt(PaymentQuarterlyAmt) * parseInt(PlanQuarterYear);

    let HalYearAmountData = PaymentHalfYearlyAmt == '' ? '0' : parseInt(PaymentHalfYearlyAmt) * parseInt(PlanHalfYear);

    let MonthlyAmountData = PaymentMontlyAmt == '' ? '0' : parseInt(PaymentMontlyAmt) * parseInt(PlanYear);

    let DemarcationAMountData = SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.demarcation.replace(/,/g, '');

    let PossessionAMountData = SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.possession.replace(/,/g, '');

    let AllocationAmountData = SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.allocation.replace(/,/g, '');

    let ConfirmationAmountData = SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.confirmation.replace(/,/g, '');

    let BookingAmountData = SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.booking.replace(/,/g, '');

    let TotalAmountPriceData = SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.TotalAmount;

return (
<>
<div className="container-fluid mt-xl-50 mt-sm-30 mt-15 pt-4">
<div className="hk-pg-header pt-4 pb-4 mt-2 mb-3 d-flex justify-content-between">
<div>
<h2 className="hk-pg-title font-weight-600">Generate Payment Plan</h2>
{/* <button onClick={handlePrint}>Print this out!</button> */}

<div ref={componentRef} style={{width:'100%',height:'auto'}}>
  {/* <h1>This is Testing Print</h1> */}
</div>
</div>
<div>

</div>

</div>

<div class="row pb-3">

<div className="col-md-2">
<div className="form-group">
<label htmlFor="">Booking</label>
{
  PaymentPlanType == 'customized' ? (
    <input type="text" className="form-control" name="Booking" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.booking}`} onChange={e=>setBookingAmountValue(e.target.value)} value={BookingAmountValue}/>
  ):(
    <input type="text" className="form-control" name="Booking" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.booking}`} onChange={e=>setBookingAmountValue(e.target.value)} value={BookingAmountValue} disabled/>
  )
}

</div>
</div>

<div className="col-md-2">
<div className="form-group">
<label htmlFor="">Allocation</label>
{
  PaymentPlanType == 'customized' ? (
    <input type="text" className="form-control" name="Allocation" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.allocation}`} onChange={e=>setAllocationAmountValue(e.target.value)} value={AllocationAmountValue}/>
  ):(
    <input type="text" className="form-control" name="Allocation" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.allocation}`} onChange={e=>setAllocationAmountValue(e.target.value)} value={AllocationAmountValue} disabled/>
  )
}

</div>
</div>

<div className="col-md-2">
<div className="form-group">
<label htmlFor="">Confirmation</label>
{
  PaymentPlanType == 'customized' ? (
    <input type="text" className="form-control" name="Confirmation" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.confirmation}`} onChange={e=>setConfirmationAmountValue(e.target.value)} value={ConfirmationAmountValue} />
  ):(
    <input type="text" className="form-control" name="Confirmation" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.confirmation}`} onChange={e=>setConfirmationAmountValue(e.target.value)} value={ConfirmationAmountValue} disabled/>
  )
  }
</div>
</div>

<div className="col-md-2">
<div className="form-group">
<label htmlFor="">Possession</label>
{
  PaymentPlanType == 'customized' ? (
  <input type="text" className="form-control" name="Possession" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.possession}`} onChange={e=>setPossessionAmountValue(e.target.value)} value={PossessionAmountValue} />
  ):(
    <input type="text" className="form-control" name="Possession" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.possession}`} onChange={e=>setPossessionAmountValue(e.target.value)} value={PossessionAmountValue} disabled/>
  )
}
</div>
</div>

<div className="col-md-2">
<div className="form-group">
<label htmlFor="">Demarcation</label>
{
  PaymentPlanType == 'customized' ? (
<input type="text" className="form-control" name="Demarcation" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.demarcation}`} onChange={e=>setDemarcationAmountValue(e.target.value)} value={DemarcationAmountValue} />
  ):(
<input type="text" className="form-control" name="Demarcation" placeholder={`${SingleInveResult == '' || SingleInveResult.length == 0 || SingleInveResult == null || SingleInveResult == undefined ? '' : SingleInveResult.ProjectFilter.demarcation}`} onChange={e=>setDemarcationAmountValue(e.target.value)} value={DemarcationAmountValue} disabled/>
  )
}
</div>
</div>


</div>

<div className="row">
<div class="form-group col-md-3">
  <label for="inputPassword4">Payment Plan Type</label>
  <select id="paymentPlanType" name="paymentPlanType" class="form-control" required onChange={e=>setPaymentPlanType(e.target.value)} value={PaymentPlanType}>
    <option value="" selected>Select Option</option>
    <option value="autogenerated">Auto Generated</option>
    <option value="customized">Customized</option>
  </select>
</div>

<div className={`form-group col-md-3 ${PaymentPlanShow == false ? 'd-none' : ''}`}>
  <label for="inputPassword4">Payment Plan</label>
  <select id="paymentPlanStatus" name="paymentPlanStatus" class="form-control" required onChange={e=>setPaymentPlanStatus(e.target.value)} value={PaymentPlanStatus}>
    <option value="" selected>Select Option</option>
    <option value="3">3 Years</option>
    <option value="4">4 Years</option>
    <option value="5">5 Years</option>
  </select>
</div>

<div className={`col-md-3 ${PaymentPlanShow == false ? 'd-none' : ''}`}>
<div className="form-group">
<label htmlFor="">Half Yearly Installement</label>
<input type="text" className="form-control" name="halfYearlyInstallement" placeholder='Half Yearly Installement' onChange={e=>setPaymentHalfYearlyAmt(e.target.value)} value={PaymentHalfYearlyAmt}/>
</div>
</div>

<div className={`col-md-2 ${PaymentPlanShow == false ? 'd-none' : ''}`}>
<div className="form-group">
<label htmlFor="">Quarterly Installement</label>
<input type="text" className="form-control" name="quarterlyInstallement" placeholder='Quarterly Installement' onChange={e=>setPaymentQuarterlyAmt(e.target.value)} value={PaymentQuarterlyAmt}/>
</div>
</div>



<div className={`col-md-2 ${PaymentPlanShow == false ? 'd-none' : ''}`}>
<div className="form-group">
<label htmlFor="">Monthly Installement</label>
<input type="text" className="form-control" name="monthlyInstallement" placeholder='Monthly Installement' onChange={e=>setPaymentMontlyAmt(e.target.value)} value={PaymentMontlyAmt}/>
</div>
</div>
<div class="col-md-2">
<div class="form-group" style={{marginTop:'33px'}}>
  {
    PaymentPlanTypeButton == false ? (
      <button type="submit" name="find" class="btn btn-primary" style={{width:'100%'}} disabled>Generate Plan</button>
    ):(
      <button type="submit" name="find" class="btn btn-primary" style={{width:'100%'}} >Generate Plan</button>
    )
  }

</div>
</div>
</div>

<OtherNavigation/>

<div className="row">
  <div className="col-md-12">

    
    <p>Plot Amount : {TotalAmountPriceData}</p>
    <p>Plot Size : {SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.size}</p>
    <p>Booking : {BookingAmountData}</p>
    <p>Confirmation : {ConfirmationAmountData}</p>
    <p>Allocation : {AllocationAmountData}</p>
    
    <p>Montly Installement : { PaymentMontlyAmt == '' ? '0' :  PlanYear } X {PaymentMontlyAmt} = { MonthlyAmountData }</p>
    <p>Half Yearly Installement : { PaymentHalfYearlyAmt == '' ? '0' :  PlanHalfYear } X {PaymentHalfYearlyAmt} = { HalYearAmountData }</p>
    <p>Quarterly Installement : { PaymentQuarterlyAmt == '' ? '0' :  PlanQuarterYear} X {PaymentQuarterlyAmt} = { QuarterlyAmountData }</p>
    <p>Demarcation : {DemarcationAMountData}</p>
    <p>Possession : {PossessionAMountData}</p>

    <p>Total : {parseInt(TotalAmountPriceData) - parseInt(BookingAmountValue) - parseInt(ConfirmationAmountValue) - parseInt(AllocationAmountValue) - parseInt(MonthlyAmountData) - parseInt(HalYearAmountData) - parseInt(QuarterlyAmountData) - parseInt(DemarcationAmountValue) - parseInt(PossessionAmountValue)}</p>
  </div>
</div>
{/* <div className="row pt-4 pb-4">
  <div className="col-md-12 text-right">
    <p className='badge badge-danger'>Balance Amount : 0</p>
  </div>
</div> */}
<div class="hk-row">
<div class="col-lg-12">
<div class="card">
<div class="card-body">
<div class="row">
<div class="col-sm">
<div class="table-wrap">
<div class="table-responsive">
<table class="table table-hover table-bordered mb-0">
  <thead>
      <tr>
          <th>Due date</th>
          <th>Due Amount</th>
          <th>Particular</th>
          <th>Recieved Amount</th>
          <th>Remaining Amount</th>
          <th>Recieved Date</th>
          <th>Reference</th>
          <th>{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ModeOfPaymentTitle}</th>
          <th>Action</th>
      </tr>
  </thead>
  <tbody>
    
    <tr>
        <td><Moment format="DD/MM/YYYY">{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.created_at}</Moment>
              </td>
        <td>{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.TotalAmount}</td>
        <td>Booking</td>
        <td><input type="text" className='form-control' name="recAmount" value={RecievedAmount} /></td>
        <td>{TOtalAmc}</td>
        <td><Moment format="DD/MM/YYYY">{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.created_at}</Moment></td>
        <td>
            <select class='form-control'>
                <option value="" selected>Select Reference</option>
                
              {
                SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ModeOfPayment == 'cash' ? (
                  <option value="cash" selected>Cash</option>
                ):(
                  <option value="cash">Cash</option>
                )
              }

              {
                SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ModeOfPayment == 'Bank (Online)' ? (
                  <option value="Bank (Online)" selected>Bank (Online)</option>
                ):(
                  <option value="Bank (Online)">Bank (Online)</option>
                )
              }

              {
                SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ModeOfPayment == 'Cheque' ? (
                  <option value="Cheque" selected>Cheque</option>
                ):(
                  <option value="Cheque">Cheque</option>
                )
              }

              {
                SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ModeOfPayment == 'Pay Order | DD' ? (
                  <option value="Pay Order | DD" selected>Pay Order | DD</option>
                ):(
                  <option value="Pay Order | DD">Pay Order | DD</option>
                )
              }

              {
                SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ModeOfPayment == 'File (Merging)' ? (
                  <option value="File (Merging)" selected>File (Merging)</option>
                ):(
                  <option value="File (Merging)">File (Merging)</option>
                )
              }
               
            </select>
        </td>
        <td><input type="text" className='form-control' name="chequeNo" value={SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ModeOfPaymentValues} /></td>
        <td>
              {
                TOtalAmc == 0 ? <></> :  <NavLink to="#"><button className="btn btn-danger btn-sm btn-rounded">Payment Required (50,0000)</button></NavLink>
              }
         
        </td>
    </tr>
    <tr>
        <td><Moment add={{ days: 30 }} format="DD/MM/YYYY">{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.created_at}</Moment></td>
        <td>{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.allocation}</td>
        <td>Allocation</td>
        <td><input type="text" className='form-control' name="recAmount" value="0" /></td>
        <td>{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.allocation}</td>
        <td><input type="date" className='form-control' name="recievedDate" value="" /></td>
        <td>
        <select class='form-control'>
                <option value="" selected>Select Reference</option>
                  <option value="cash">Cash</option>
                  <option value="Bank (Online)">Bank (Online)</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Pay Order | DD">Pay Order | DD</option>
                  <option value="File (Merging)">File (Merging)</option>
              
            </select>
        </td>
        <td>
          <input type="text" className='form-control' name="chequeNo" value="" /></td>
        <td  width={120}><NavLink to="#"><button className="btn btn-danger btn-sm">Pay Now</button></NavLink></td>
    </tr>

    <tr>
        <td><Moment add={{ days: 60 }} format="DD/MM/YYYY">{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.created_at}</Moment></td>
        <td>{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.confirmation}</td>
        <td>Confirmation</td>
        <td><input type="text" className='form-control' name="recAmount" value="0" /></td>
        <td>{SingleInveResult == '' || SingleInveResult == null ? '' : SingleInveResult.ProjectFilter.confirmation}</td>
        <td><input type="date" className='form-control' name="recievedDate" value="" /></td>
        <td>
        <select class='form-control'>
                <option value="" selected>Select Reference</option>
                  <option value="cash">Cash</option>
                  <option value="Bank (Online)">Bank (Online)</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Pay Order | DD">Pay Order | DD</option>
                  <option value="File (Merging)">File (Merging)</option>
              
            </select>
        </td>
        <td>
          <input type="text" className='form-control' name="chequeNo" value="" /></td>
        <td  width={120}><NavLink to="#"><button className="btn btn-danger btn-sm">Pay Now</button></NavLink></td>
    </tr>
    

    {/* <tr>
        <td>10-April-23</td>
        <td>10,000.00</td>
        <td>1st Installment</td>
        <td></td>
        <td>0</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td>10-May-23</td>
        <td>10,000.00</td>
        <td>2nd Installment</td>
        <td></td>
        <td>0</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>

    <tr>
        <td>10-Jun-23</td>
        <td>10,000.00</td>
        <td>3rd Installment</td>
        <td></td>
        <td>0</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>

    <tr>
        <td>10-July-23</td>
        <td>25,000.00</td>
        <td>Quarterly Installment </td>
        <td></td>
        <td>0</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>

    <tr>
        <td>10-Aug-23</td>
        <td>10,000.00</td>
        <td>4th Installment</td>
        <td></td>
        <td>0</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr> */}

  </tbody>
</table>

</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div>

</div>
</>
)
}

export default PaymentPlanGenerate