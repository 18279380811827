import React from 'react'
import OtherNavigation from '../../Includes/OtherNavigation'

const Chat = () => {
return (
<>

<div className="container-fluid mt-xl-50 mt-sm-30 mt-15 pt-4">
<OtherNavigation/>

<div class="row">
<div class="col-xl-12 pa-0">
<div class="chatapp-wrap">
<div class="chatapp-left">
<header>
<a href="javascript:void(0)" class="chat-more">
<span class="feather-icon"><i data-feather="more-vertical"></i></span>
</a>
<span class="">Chats</span>
<a href="javascript:void(0)" class="chat-compose">
<span class="feather-icon"><i data-feather="edit"></i></span>
</a>
</header>
<form role="search" class="chat-search">
<div class="input-group">
<div class="input-group-prepend">
<span class="feather-icon"><i data-feather="search"></i></span>
</div>
<input type="text" class="form-control" placeholder="Search" />
</div>
</form>
<div class="chatapp-users-list">
<div class="nicescroll-bar">
<a href="javascript:void(0);" class="media">
<div class="media-img-wrap">
<div class="avatar">
<img src="FrontAsset/dist/img/avatar1.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
<span class="badge badge-warning badge-indicator"></span>
</div>
<div class="media-body">
<div>
<div class="user-name">Muhammad Touqeer</div>
<div class="user-last-chat">No Comment</div>
</div>
<div>
<div class="last-chat-time block">1 min</div>
<div class="badge badge-success badge-pill">0</div>
</div>
</div>
</a>
<div class="chat-hr-wrap">
<hr />
</div>

</div>
</div>
</div>
<div class="chatapp-right">
<header>
<a id="back_user_list" href="javascript:void(0)" class="back-user-list">
<span class="feather-icon"><i data-feather="chevron-left"></i></span>
</a>
<div class="media">
<div class="media-img-wrap">
<div class="avatar">
<img src="FrontAsset/dist/img/avatar2.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
<span class="badge badge-success badge-indicator"></span>
</div>
<div class="media-body">
<div class="user-name">Muhammad Touqeer</div>
<div class="user-status">online</div>
</div>
</div>
<div class="chat-options-wrap">
<a href="javascript:void(0)" class=""><span class="feather-icon"><i data-feather="video"></i></span></a>
<a href="javascript:void(0)" class=""><span class="feather-icon"><i data-feather="phone"></i></span></a>
</div>
</header>
<div class="chat-body">
<div class="nicescroll-bar">
<ul class="list-unstyled">
<li class="media received">
<div class="avatar">
<img src="FrontAsset/dist/img/avatar2.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
<div class="media-body">
<div class="msg-box">
<div>
<p>Cool, lets talk about it tomorrow</p>
<span class="chat-time">10:55 AM</span>
<div class="arrow-triangle-wrap">
<div class="arrow-triangle right"></div>
</div>
</div>
</div>
<div class="msg-box">
<div>
<p>Images for new marketing pages have been sent</p>
<span class="chat-time">10:56 AM</span>
<div class="arrow-triangle-wrap">
<div class="arrow-triangle right"></div>
</div>
</div>
</div>
</div>
</li>
<li class="media received">
<div class="avatar">
<img src="FrontAsset/dist/img/avatar2.jpg" alt="user" class="avatar-img rounded-circle" />
</div>
<div class="media-body">
<div class="msg-box">
<div>
<p>Hey Ben, just a reminder that you are coming for the meeting today in the conference. We are proposing a change in the client briefing.</p>
<span class="chat-time">2:35 PM</span>
<div class="arrow-triangle-wrap">
<div class="arrow-triangle right"></div>
</div>
</div>
</div>
</div>
</li>
<li class="media sent">
<div class="media-body">
<div class="msg-box">
<div>
<p>Sure, on my way to office. Please give me quick update.</p>
<span class="chat-time">11:45 AM</span>
<div class="arrow-triangle-wrap">
<div class="arrow-triangle left"></div>
</div>
</div>
</div>
</div>
</li>

</ul>
</div>
</div>
<footer>
<div class="input-group">
<div class="input-group-prepend">
<div class="btn-file  btn btn-light">
<div class="btn-icon-wrap">
<span class="feather-icon"><i data-feather="paperclip"></i></span>
<input type="file" />
</div>
</div>
</div>
<input type="text" id="input_msg_send_chatapp" name="send-msg" class="input-msg-send form-control" placeholder="Type something" />
<div class="input-group-append">
<button type="button" class="btn  btn-light  dropdown-toggle no-caret" data-toggle="dropdown"><span class="feather-icon"><i data-feather="at-sign"></i></span></button>
</div>
</div>
</footer>
</div>
</div>
</div>
</div>

</div>
</>
)
}

export default Chat